import _fs from "fs";
import _path from "path";
import _util from "util";

var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
var fs = _fs;
var path = _path;
var util = _util;

function Y18N(opts) {
  // configurable options.
  opts = opts || {};
  (this || _global).directory = opts.directory || "./locales";
  (this || _global).updateFiles = typeof opts.updateFiles === "boolean" ? opts.updateFiles : true;
  (this || _global).locale = opts.locale || "en";
  (this || _global).fallbackToLanguage = typeof opts.fallbackToLanguage === "boolean" ? opts.fallbackToLanguage : true; // internal stuff.

  (this || _global).cache = Object.create(null);
  (this || _global).writeQueue = [];
}

Y18N.prototype.__ = function () {
  if (typeof arguments[0] !== "string") {
    return (this || _global)._taggedLiteral.apply(this || _global, arguments);
  }

  var args = Array.prototype.slice.call(arguments);
  var str = args.shift();

  var cb = function () {}; // start with noop.


  if (typeof args[args.length - 1] === "function") cb = args.pop();

  cb = cb || function () {}; // noop.


  if (!(this || _global).cache[(this || _global).locale]) this._readLocaleFile(); // we've observed a new string, update the language file.

  if (!(this || _global).cache[(this || _global).locale][str] && (this || _global).updateFiles) {
    (this || _global).cache[(this || _global).locale][str] = str; // include the current directory and locale,
    // since these values could change before the
    // write is performed.

    this._enqueueWrite([(this || _global).directory, (this || _global).locale, cb]);
  } else {
    cb();
  }

  return util.format.apply(util, [(this || _global).cache[(this || _global).locale][str] || str].concat(args));
};

Y18N.prototype._taggedLiteral = function (parts) {
  var args = arguments;
  var str = "";
  parts.forEach(function (part, i) {
    var arg = args[i + 1];
    str += part;

    if (typeof arg !== "undefined") {
      str += "%s";
    }
  });
  return (this || _global).__.apply(null, [str].concat([].slice.call(arguments, 1)));
};

Y18N.prototype._enqueueWrite = function (work) {
  (this || _global).writeQueue.push(work);

  if ((this || _global).writeQueue.length === 1) this._processWriteQueue();
};

Y18N.prototype._processWriteQueue = function () {
  var _this = this || _global;

  var work = (this || _global).writeQueue[0]; // destructure the enqueued work.

  var directory = work[0];
  var locale = work[1];
  var cb = work[2];

  var languageFile = this._resolveLocaleFile(directory, locale);

  var serializedLocale = JSON.stringify((this || _global).cache[locale], null, 2);
  fs.writeFile(languageFile, serializedLocale, "utf-8", function (err) {
    _this.writeQueue.shift();

    if (_this.writeQueue.length > 0) _this._processWriteQueue();
    cb(err);
  });
};

Y18N.prototype._readLocaleFile = function () {
  var localeLookup = {};

  var languageFile = this._resolveLocaleFile((this || _global).directory, (this || _global).locale);

  try {
    localeLookup = JSON.parse(fs.readFileSync(languageFile, "utf-8"));
  } catch (err) {
    if (err instanceof SyntaxError) {
      err.message = "syntax error in " + languageFile;
    }

    if (err.code === "ENOENT") localeLookup = {};else throw err;
  }

  (this || _global).cache[(this || _global).locale] = localeLookup;
};

Y18N.prototype._resolveLocaleFile = function (directory, locale) {
  var file = path.resolve(directory, "./", locale + ".json");

  if ((this || _global).fallbackToLanguage && !this._fileExistsSync(file) && ~locale.lastIndexOf("_")) {
    // attempt fallback to language only
    var languageFile = path.resolve(directory, "./", locale.split("_")[0] + ".json");
    if (this._fileExistsSync(languageFile)) file = languageFile;
  }

  return file;
}; // this only exists because fs.existsSync() "will be deprecated"
// see https://nodejs.org/api/fs.html#fs_fs_existssync_path


Y18N.prototype._fileExistsSync = function (file) {
  try {
    return fs.statSync(file).isFile();
  } catch (err) {
    return false;
  }
};

Y18N.prototype.__n = function () {
  var args = Array.prototype.slice.call(arguments);
  var singular = args.shift();
  var plural = args.shift();
  var quantity = args.shift();

  var cb = function () {}; // start with noop.


  if (typeof args[args.length - 1] === "function") cb = args.pop();
  if (!(this || _global).cache[(this || _global).locale]) this._readLocaleFile();
  var str = quantity === 1 ? singular : plural;

  if ((this || _global).cache[(this || _global).locale][singular]) {
    str = (this || _global).cache[(this || _global).locale][singular][quantity === 1 ? "one" : "other"];
  } // we've observed a new string, update the language file.


  if (!(this || _global).cache[(this || _global).locale][singular] && (this || _global).updateFiles) {
    (this || _global).cache[(this || _global).locale][singular] = {
      one: singular,
      other: plural
    }; // include the current directory and locale,
    // since these values could change before the
    // write is performed.

    this._enqueueWrite([(this || _global).directory, (this || _global).locale, cb]);
  } else {
    cb();
  } // if a %d placeholder is provided, add quantity
  // to the arguments expanded by util.format.


  var values = [str];
  if (~str.indexOf("%d")) values.push(quantity);
  return util.format.apply(util, values.concat(args));
};

Y18N.prototype.setLocale = function (locale) {
  (this || _global).locale = locale;
};

Y18N.prototype.getLocale = function () {
  return (this || _global).locale;
};

Y18N.prototype.updateLocale = function (obj) {
  if (!(this || _global).cache[(this || _global).locale]) this._readLocaleFile();

  for (var key in obj) {
    (this || _global).cache[(this || _global).locale][key] = obj[key];
  }
};

exports = function (opts) {
  var y18n = new Y18N(opts); // bind all functions to y18n, so that
  // they can be used in isolation.

  for (var key in y18n) {
    if (typeof y18n[key] === "function") {
      y18n[key] = y18n[key].bind(y18n);
    }
  }

  return y18n;
};

export default exports;